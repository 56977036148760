
.flag-prefix {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 15px;
  z-index: 1;
  pointer-events: none;
}

.country-code-prefix {
  position: absolute;
  left: 38px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  pointer-events: none;
  font-size: inherit;
  color: inherit;
}

input {
  padding-left: 55px !important;
  padding-bottom: 5px !important;
}

.error {
  display: block;
  width: 100%;
  font-size: 80%;
  color: #ff1721 !important;
  margin-top: 5px;
}

:deep(.iti__country-container) {
  right: auto !important;
  left: 0;
}
